
import { defineComponent, shallowRef } from 'vue'
import useModel from '@/hooks/useModel'
import { downloadFromStream } from "@/utils/payment/downloadFile"
import {
  bounsAdjustmentOfferTypes,
  bonusAdjustmentExport
} from '@/API/checking/checkingSICCO'

function debounce(fn: Function, delay: number) {
  let timer: number | undefined = undefined
  return function() {
    if (timer) {
      clearTimeout(timer)
      timer = undefined
    }
    timer = setTimeout(() => {
      fn()
    }, delay)
  }
}

export default defineComponent({
  props: {
    visiable: {
      type: Boolean,
      default: false
    },
  },
  emits: [
    'update:visiable',
    'ok'
  ],
  setup(props, { emit }) {
    const modalVisiable = useModel(props, 'visiable')

    const programCode = shallowRef('')
    const offerType = shallowRef('')
    const offerTypes = shallowRef<string[]>([])

    const findOfferTypes = debounce(async () => {
      offerTypes.value = await bounsAdjustmentOfferTypes(programCode.value)
    }, 300)

    const handleInputProgramCode = async () => {
      offerType.value = ''
      offerTypes.value = []
      if (programCode.value) {
        findOfferTypes()
      }
    }

    const handleExport = async () => {
      const data = await bonusAdjustmentExport(programCode.value, offerType.value)
      downloadFromStream(data)
      emit('update:visiable', false)
    }

    return {
      modalVisiable,
      programCode,
      offerType,
      offerTypes,
      handleInputProgramCode,
      handleExport
    }
  }
})
