import axios from "axios";
import { fetchGet, fetchPost } from "../index";
import type { BMBSClaimUploadResult } from "@/views/Checking/SICCOChecking/types";

//! 获取checking round
const getCheckingRoundList = (params: any): Promise<any> => {
  // return fetchGet(
  //     //TODO 修改传参逻辑
  //     `/claimapi/checking/plan/detail/checking/round?bus=MB&assignee=RT`
  //     // `/claimapi/checking/plan/detail/checking/round?bus=${params.bus}&assignee=${params.assignee}`
  // );
  // return fetchGet('/claimapi/checking/plan/detail/checking/round', params);
  return fetchGet("/claimapi/checking/plan/detail/checking/round", params);
};

//! 获取batch
const getBatchList = (params: any): Promise<any> => {
  return fetchPost("/claimapi/checking/plan/detail/checking/batch", params);
};

//! 根据 batch 获取数据
const getDataByBatch = (params: any): Promise<any> => {
  return fetchPost("/claimapi/checking/plan/detail/checking/main/page", params);
};
//! 根据 batch 获取数据
const getDataByBatchAdditional = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/checking/plan/detail/checking/main/page/additional",
    params
  );
};

//! search 查询接口
const searchData = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/checking/plan/detail/checking/main/page/search",
    params
  );
};

//! OASIS-4953 Checking批量插入或更新文档匹配结果 0914修改了
const modifyCheckingInitStatus = (params: any): Promise<any> => {
  return fetchGet("/claimapi/checking/modifyCheckingInitStatus", { params });
};
//全年政策增加全部核查
const modifyCheckingAllInitStatus = (params: any): Promise<any> => {
  return fetchGet("/claimapi/checking/modifyCheckingAllInitStatus", { params });
};
// OASIS-12424 BMBS Claim Export 下载模板
export const bmbsClaimExport = () => {
  return axios.post("/claimapi/bmbs/claim/export", null, {
    responseType: "blob",
  });
};
// OASIS-12422 Bonus Adjustment Template Export 下载模板
export const bounsAdjustmentOfferTypes = (
  programCode: string
): Promise<string[]> => {
  return fetchGet(`/claimapi/bonus/adjustment/listOfferType/${programCode}`);
};

export const bonusAdjustmentExport = (
  programCode: string,
  offerType: string
) => {
  return axios.post(
    `/claimapi/bonus/adjustment/export/${programCode}/${offerType}`,
    null,
    {
      responseType: "blob",
    }
  );
};

// OASIS-12429  BMBS Claim Import
export const bmbsClaimCheck = (
  programCode: string,
  offerType: string,
  file: Blob,
  config = {}
): Promise<{
  success: BMBSClaimUploadResult[];
  failed: BMBSClaimUploadResult[];
}> => {
  const formData = new FormData();
  formData.set("file", file);
  return fetchPost(
    `/claimapi/bmbs/claim/importClaim/${programCode}/${offerType}`,
    formData,
    config
  );
};

export const bmbsClaimSave = (
  programCode: string,
  offerType: string,
  file: Blob,
  config = {}
): Promise<any> => {
  const formData = new FormData();
  formData.set("file", file);
  return fetchPost(
    `/claimapi/bmbs/claim/submitClaim/${programCode}/${offerType}`,
    formData,
    config
  );
};

// OASIS-12423 Bonus Adjustment Import
export const bonusAdjustmentCheck = (
  programCode: string,
  offerType: string,
  file: Blob,
  config = {}
): Promise<any> => {
  const formData = new FormData();
  formData.set("file", file);
  return fetchPost(
    `/claimapi/bonus/adjustment/importBonusAdjustment/${programCode}/${offerType}`,
    formData,
    config
  );
};

export const bonusAdjustmentSave = (
  programCode: string,
  offerType: string,
  file: Blob,
  config = {}
): Promise<any> => {
  const formData = new FormData();
  formData.set("file", file);
  return fetchPost(
    `/claimapi/bonus/adjustment/submitBonusAdjustment/${programCode}/${offerType}`,
    formData,
    config
  );
};

export default {
  getCheckingRoundList,
  getBatchList,
  searchData,
  getDataByBatch,
  getDataByBatchAdditional,
  modifyCheckingInitStatus,
  modifyCheckingAllInitStatus,
  bmbsClaimExport,
  bounsAdjustmentOfferTypes,
  bonusAdjustmentExport,
  bmbsClaimCheck,
  bmbsClaimSave,
  bonusAdjustmentCheck,
  bonusAdjustmentSave,
};
