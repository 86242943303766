
import {
  ref,
  defineComponent,
  reactive,
  computed,
  toRefs,
  onMounted,
  watch,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";
import Batch from "@/views/Checking/components/Batch.vue";
import CheckingRoundModal from "@/views/Checking/components/CheckingRoundModal.vue";
import SICCOApi from "@/API/checking/checkingSICCO";
import { getUser } from "@/API/checking/checkingManagement";
import fixTable from "@/hooks/checking/useFixTable";
import { SiccoCheckingUploadType } from "@/views/Checking/SICCOChecking/types";
import BonusAdjustmentModal from "@/views/Checking/SICCOChecking/bonusAdjustmentModal.vue";
import Upload from "@/views/Checking/SICCOChecking/upload.vue";
import { downloadFromStream } from "@/utils/payment/downloadFile";
import { message } from "ant-design-vue";
interface BatchDataInf {
  batchId: string;
  batchName: string;
  submissionDate: string;
  plan: number;
  additionalNum: number;
  roundId: string;
}

interface CheckingRoundInf {
  checkingRound: string;
  id: string;
  batchCount: number;
  planDetailIds: string;
}

export default defineComponent({
  components: { Batch, CheckingRoundModal, BonusAdjustmentModal, Upload },
  setup() {
    const router = useRouter();
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 20,
    });
    const currentPlanDetailIds = ref<string>("");
    const currentRoundId = ref<string>("");
    const checkingRound = ref<string>("");
    const checkingRoundId = ref<string>("");
    const checkingRoundList = ref<CheckingRoundInf[]>([]);
    const checkingRoundVisible = ref<boolean>(false);
    const programName = ref<string>("");
    const programCode = ref<string>("");
    const dealerSwtCode = ref<string>("");
    const assignee = ref<string>("");
    const bu = ref<string | null>(null);
    const buList = ref<any[]>([]);
    // const buList = ref<string[]>(['MB', 'EQ', 'AMG', 'VAN', 'DENZA']);
    const batchData = ref<BatchDataInf[]>([]);
    const columns = [
      { title: "Business Unit", dataIndex: "bu", width: 150 },
      {
        title: "Program Code",
        dataIndex: "programCode",
        width: 200,
      },
      /*{
                title: 'SWT Code',
                dataIndex: 'dealerSwtCode',
                width: 200,
            },*/
      {
        title: "Program Name",
        dataIndex: "programNameEn",
        width: 400,
      },
      {
        title: "Program Category",
        dataIndex: "offerTypeNameEn",
        width: 200,
      },
      {
        title: "Eligible Period",
        width: 250,
        slots: { customRender: "period" },
      },
      { title: "Dealer qty", dataIndex: "dealerQty", width: 100 },
      { title: "Claim qty", dataIndex: "claimQty", width: 100 },
      {
        title: "Unchecked qty",
        dataIndex: "uncheckedQty",
        width: 150,
      },
      {
        title: "Operation",
        dataIndex: "operation",
        width: 450,
        slots: { customRender: "operation" },
      },
    ];
    const visible = ref<boolean>(false);
    const tableData = ref([]);
    //* 限定表格
    const height: number =
      100 + 50 + 10 + 100 + 10 + 90 + 10 + 60 + 10 + 10 + 48 + 35;
    const tableHeight = fixTable(height);
    const submissionDate = ref<string>("");
    const batchName = ref<string>("");
    const searchPattern = ref<string>("plan");

    const currentChecnkingPlan = computed(() => {
      if (checkingRound.value && batchName.value && submissionDate.value) {
        return `${checkingRound.value} / ${batchName.value}: ${submissionDate.value}`;
      } else {
        return "";
      }
    });

    //* 查询 重置
    const getDataBySearch = () => {
      const params = {
        bu: bu.value,
        programCode: programCode.value,
        dealerSwtCode: dealerSwtCode.value,
        programName: programName.value,
        current: pagination.currentPage - 1,
        size: pagination.pageSize,
        // assignee: 'RT',
        assignee: assignee.value,
      };
      SICCOApi.searchData(params).then((res) => {
        tableData.value = res.data;
        pagination.total = parseInt(res.totalNum);
      });
    };

    const search = () => {
      searchPattern.value = "search";
      pagination.currentPage = 1;
      getDataBySearch();
    };

    const reset = () => {
      programCode.value = "";
      dealerSwtCode.value = "";
      programName.value = "";
      bu.value = null;
    };

    //* 获取 checking round 数据
    const getCheckingRoundList = () => {
      const bus = buList.value.map((bu) => {
        return bu.nameCn;
      });
      const params = {
        bus,
        assignee: assignee.value,
      };
      return SICCOApi.getCheckingRoundList({ params }).then((res) => {
        checkingRoundList.value = res.data;
        if (res.data.length !== 0) {
          const selectedRound = checkingRoundList.value[0];
          checkingRound.value = selectedRound.checkingRound;
          checkingRoundId.value = selectedRound.id;
          currentPlanDetailIds.value = selectedRound.planDetailIds;
        }
      });
    };

    //* 获取 batch 数据
    const getBatchList = () => {
      if (currentPlanDetailIds.value === "") return;
      const params = {
        planDetailIds: currentPlanDetailIds.value,
        role: "1",
      };
      return SICCOApi.getBatchList(params).then((res) => {
        batchData.value = res.data;
      });
    };

    //* 根据batch来获取数据 plan
    const getDataByBatch = () => {
      const bus = buList.value.map((bu) => {
        return bu.nameCn;
      });
      const params = {
        bus: bus.join(","),
        current: pagination.currentPage - 1,
        planDetailIds: currentPlanDetailIds.value,
        size: pagination.pageSize,
        submissionDate: submissionDate.value,
      };
      SICCOApi.getDataByBatch(params).then((res) => {
        tableData.value = res.data;
        pagination.total = parseInt(res.totalNum);
      });
    };

    //* 根据batch来获取数据 additional
    const getDataByBatchAdditional = () => {
      const bus = buList.value.map((bu) => {
        return bu.nameCn;
      });
      const params = {
        bus: bus.join(","),
        current: pagination.currentPage - 1,
        roundId: currentRoundId.value,
        size: pagination.pageSize,
        batchName: batchName.value,
      };
      SICCOApi.getDataByBatchAdditional(params).then((res) => {
        tableData.value = res.data;
        pagination.total = parseInt(res.totalNum);
      });
    };

    //* 开始获取数据逻辑
    const notAllowSearch = ref(true);
    onMounted(() => {
      getUser()
        .then((res) => {
          assignee.value = res.usrnm;
          buList.value = res.buList.filter((ele: any) => {
            return ele.parentId === null;
          });
        })
        .then(() => {
          return getCheckingRoundList();
        })
        .then(() => {
          getBatchList();
        })
        .then((_) => {
          notAllowSearch.value = false;
        });
    });

    //? Checking Round modal
    const showCheckingRoundModal = () => {
      searchPattern.value = "plan";
      checkingRoundVisible.value = true;
    };
    const closeModal = () => {
      checkingRoundVisible.value = false;
    };

    // 选择其他的 checking round
    const selectCheckingRound = (record: any) => {
      checkingRound.value = record.checkingRound;
      checkingRoundId.value = record.id;
      currentPlanDetailIds.value = record.planDetailIds;
      getBatchList();
      reset();
    };

    //? 分页操作
    const pageChange = (page: number) => {
      pagination.currentPage = page;
      if (searchPattern.value === "plan") {
        getDataByBatch();
      }
      if (searchPattern.value === "additional") {
        getDataByBatchAdditional();
      }
      if (searchPattern.value === "search") {
        getDataBySearch();
      }
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 1;
      if (searchPattern.value === "plan") {
        getDataByBatch();
      }
      if (searchPattern.value === "additional") {
        getDataByBatchAdditional();
      }
      if (searchPattern.value === "search") {
        getDataBySearch();
      }
    };

    // 跳转到详情页
    const jumpToDetails = (record: any) => {
      const params = {
        programId: record.programId,
        offerTypeId: record.offerTypeId,
      };
      SICCOApi.modifyCheckingInitStatus(params).then((res) => {
        router.push({
          name: "SICCOCheckingDetail",
          params: {
            programId: record.programId,
            programCode: record.programCode,
            dealerSwtCode: record.dealerSwtCode,
            programName: record.programNameEn,
            programCategory: record.offerTypeNameEn,
            offerTypeId: record.offerTypeId,
            bu: record.bu,
            offerTypeNameEn: record.offerTypeNameEn,
            checkingPlan: currentChecnkingPlan.value,
            batchName: batchName.value,
            submissionDate: submissionDate.value,
            checkingRoundId: checkingRoundId.value,
          },
        });
      });
    };

    let programId: any;
    let offerTypeId: any;
    const clickCheckAll = (record: any) => {
      programId = record.programId;
      offerTypeId = record.offerTypeId;
      visible.value = true;
    };
    //Check all锁定状态
    const LockStatus = () => {
      const params = {
        programId: programId,
        offerTypeId: offerTypeId,
      };
      SICCOApi.modifyCheckingAllInitStatus(params).then((res) => {
        visible.value = false;
        message.success("handle successfully");
      });
    };
    // 点击plan后面的数字 或者 自动选择显示在最前面的batch的plan
    const selectBatchPlan = (item: any) => {
      reset();
      searchPattern.value = "plan";
      currentRoundId.value = item.roundId;
      batchName.value = item.batchName;
      submissionDate.value = item.submissionDate;
      pagination.currentPage = 1;
      getDataByBatch();
    };

    const selectBatchAdditional = (item: any) => {
      reset();
      searchPattern.value = "additonal";
      currentRoundId.value = item.roundId;
      batchName.value = item.batchName;
      submissionDate.value = item.submissionDate;
      pagination.currentPage = 1;
      getDataByBatchAdditional();
    };
    // 是否显示Bonus Adjustement导出模板时的选择对话框
    const bonusAdjustmentModalvisiable = ref(false);

    const handleToExportBonusAdjustmentTemplate = async () => {
      bonusAdjustmentModalvisiable.value = true;
    };

    const handleExportBmbsClaimTemplate = async () => {
      const blob = await SICCOApi.bmbsClaimExport();
      downloadFromStream(blob);
    };

    const uploadDrawerVisiable = ref(false);
    const uploadType = ref(SiccoCheckingUploadType.BonusAdjustment);
    const selectedProgramCode = ref("");
    const selectedOfferType = ref("");

    const handleToImport = (
      record: { programCode: string; offerTypeNameEn: string },
      type: SiccoCheckingUploadType
    ) => {
      uploadType.value = type;
      selectedProgramCode.value = record.programCode;
      selectedOfferType.value = record.offerTypeNameEn;
      uploadDrawerVisiable.value = true;
    };

    return {
      checkingRound,
      checkingRoundList,
      checkingRoundVisible,
      programName,
      programCode,
      dealerSwtCode,
      bu,
      buList,
      currentChecnkingPlan,
      showCheckingRoundModal,
      closeModal,
      selectCheckingRound,
      batchData,
      ...toRefs(pagination),
      columns,
      visible,
      tableData,
      tableHeight,
      jumpToDetails,
      clickCheckAll,
      LockStatus,
      pageChange,
      sizeChange,
      selectBatchPlan,
      selectBatchAdditional,
      reset,
      search,
      bonusAdjustmentModalvisiable,
      handleToExportBonusAdjustmentTemplate,
      handleExportBmbsClaimTemplate,
      SiccoCheckingUploadType,
      uploadType,
      selectedProgramCode,
      selectedOfferType,
      uploadDrawerVisiable,
      handleToImport,
      notAllowSearch,
    };
  },
});
