export enum SiccoCheckingUploadType {
  BonusAdjustment = 'Adjustment',
  BMBSClaim = 'Claim'
}

export interface BMBSClaimUploadResult {
  checkResult: string;
  checkingComments: string;
  dealerCode: string;
  dealerName: string;
  finalBonus: string;
  offerType: string;
  programCode: string;
  vin: string;
}